.never-forgotten-showcase-cover {
  background-image: url('../../project-photos/never-forgotten/IMG_3293-thumb-small.jpg');
  background-size: cover !important;
}

.one-liner-showcase-cover {
  background-image: url('../../project-photos/one-liner/IMG_3363-low.jpg');
  background-size: cover !important;
}

.symbol-showcase-cover {
  background-image: url('../../project-photos/symbol/original_drawing_01.jpg');
}

.roma-showcase-cover {
  background-image: url('../../project-photos/roma/original_drawing_01.jpg');
}

.monk-showcase-cover {
  background-image: url('../../project-photos/monk/original_drawing_01.jpg');
}

.yuanyuan-showcase-cover {
  background-image: url('../../project-photos/yuanyuan/original_drawing_01.jpg');
}

.phantom-showcase-cover {
  background-image: url('../../project-photos/phantom/original_drawing_02.png');
}

.arrow-showcase-cover {
  background-image: url('../../project-photos/arrow/original_drawing_02.png');
}

.modern-china-showcase-cover {
  background-image: url('../../project-photos/modern-china/original_drawing_01.png');
}

@media all and (min-width: 1250px) {
  .showcase-element-photo {
    height: 45vh;
  }
}