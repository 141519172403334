.never-forgotten-home-page-showcase-cover {
  height: 40vh;
  background-image: url('../../project-photos/never-forgotten/IMG_3293-low.jpg');
  background-size: cover !important;
}

.one-liner-home-page-showcase-cover {
  height: 25vh;
  background-image: url('../../project-photos/one-liner/IMG_3363-low.jpg');
  background-size: cover !important;
  background-position: center;
}

@media all and (min-width: 1250px) {
  .showcase-element-photo {
    height: 45vh;
  }

  .never-forgotten-home-page-showcase-cover {
    background-position: bottom center !important;
  }
}