.App-header {
  min-height: 90vh;
  background: linear-gradient(127deg, rgb(0 0 0) 0%, rgb(25 25 29) 36%, rgb(45 46 48) 69%);
  display: grid;
  grid-template-columns: 1fr 90vw 1fr;
  grid-template-rows: 4rem 2rem 1rem 15rem 30rem 5rem 1fr;
  grid-template-areas:
      " . . ."
      " . header-logo . "
      " . header-tagline . "
      " . header-menu . "
      " . header-headline . "
      " . header-call-to-action . ";
}

.App-logo {
  width: 10rem;
  height: auto;
  grid-area: header-logo;
  text-decoration: none;
  border: 0;
}

.App-tagline {
  grid-area: header-tagline;
  font-size: 1.1rem;
  letter-spacing: 0.22rem;
  /*text-align: center;
  text-decoration: overline;
  margin-top: 0.7rem;*/
}

.App-menu {
  grid-area: header-menu;
  margin-top: 1rem;
  text-transform: uppercase;
  color: #e8e8f2;
}

.App-menu li {
  margin-right: 1rem;
  font-size: 1.1rem;
}

.App-menu a {
  text-decoration: none;
  border: 0;
}

.Header-headline {
  grid-area: header-headline;
  color: white;
}

.Header-call-to-action {
  grid-area: header-call-to-action;
  color: white;
  margin: 0;
  margin-left: auto;
}

.Header-call-to-action span {
  font-size: 1.5rem;
  line-height: 2.5rem;
  height: 2.5rem;
}

.Header-call-to-action button {
  margin-left: 1rem;
  border-style: none;
  font-size: 1.2rem;
  padding: 0rem 2rem;
  border-radius: 0.75rem;
  color: white;
  cursor: pointer;
  height: 2.5rem;
  /* line-height: 2.5rem; */
  background: rgb(246,58,107);
  background: linear-gradient(127deg, rgb(172 33 69) 0%, rgb(223 52 44) 46%, rgb(173 37 37) 83%);
  border: 1px solid #350e0e;
  font-weight: 600;
}

.Headline-one {
  /* border: 0.2rem solid white; */
  padding: 2.5rem 5.5rem;
  width: 80%;
  /* height: 20rem; */
  margin: auto;
  box-sizing: border-box;
  border-width: 0.5rem;
  border-style: solid;
  border-image: linear-gradient(#fff, #fff);
  /* background-size: 400% 400%; */
  /* background: linear-gradient(120deg, #6559ae, #ff7159, #6559ae) padding-box, linear-gradient(to right, #6559ae, #ff7159, #6559ae) border-box; */
  /* border: 2rem solid transparent; */
}

.Headline-one span {
  /* display: block;
  font-size: 6rem;
  line-height: 8rem; */
  display: block;
  font-size: 2rem;
  line-height: 4rem;
  letter-spacing: .5rem;
  text-transform: uppercase;
}

.showcase {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:  space-between;
}

.showcase-element {
  width: 30%;
  margin: 1rem 0;
  /*border: 0.1rem solid #969393;*/
  /*border: 0.1rem solid #e4dede;*/
}

.showcase-element-most-recent {
  width: 66%;
}

.showcase-element-photo {
  display: flex;
  flex-direction: column-reverse;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.showcase-element-photo span {
  padding: 1rem 1rem;
  display: block;
  font-size: 1.5rem;
  color: #1d1e21;
  background-color: rgb(236 234 231 / 50%);
  font-weight: 600;
  text-align: center;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.full-width-photo {
  width: 100%;
  height: 50vw;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.half-width-photo {
  width: 50%;
  height: 30vw;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 1rem 1.5rem;
}

.no-tracking-notice {
  font-size: 1.2rem;
  padding: 1rem;
  background-color: #fef9f3;
  border-left: 0.2rem solid #c5b7a6;
}

.not-ready-yet-notice {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 5rem 0;
}

.page-abstract {
  padding: 1rem 3rem;
  border-top: 1px solid #1d1e21;
  border-bottom: 1px solid #1d1e21;
  margin-top: 3rem;
  margin-bottom: 2rem;
  background-color: #fef9f3;
}

.quote {
  padding: 1rem 0 0 10rem;
  font-family: 'Caveat', cursive;
  position: relative;
  font-size: 1.9rem;
}

.quote:before {
  content: "“";
  font-size: 8rem;
  color: rgb(130 130 130);
  /* line-height: 0.1rem; */
  position: absolute;
  display: inline-block;
  /* top: 4.4rem; */
  left: -1rem;
}

.quote:after {
  content: "";
}

.photo-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}

.photo-gallery-item {
  width: auto;
  height: 20vw;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.photo-gallery a {
  border: 0;
}

.mailing-list-section {
  display: flex;
}

.mailing-list-text {
  box-sizing: content-box;
  padding-right: 3rem;
}

.mailing-list-form {
  display: flex;
  height: 3rem;
}

.mailing-list-email {
  border-radius: 0;
  outline: 0;
  border: 0;
  background-color: #f5f4f3;
  padding: 1rem;
  width: 20rem;
  font-size: 1.2rem;
}

.mailing-list-submit {
  border-radius: 0;
  outline: 0;
  border: 0;
  background-color: #1d1e21;
  padding: 1rem 0;
  width: 3rem;
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
}

.featured-project {
  display: flex;
}

.featured-project-cover {
  width: 60%;
}

.featured-project-text {
  width: 40%;
  box-sizing: border-box;
  padding-left: 2rem;
}

.most-recent-project {
  display: flex;
}

.most-recent-project-cover {
  width: 30%;
}

.most-recent-project-text {
  width: 70%;
  box-sizing: border-box;
  padding-left: 2rem;
}

.App-end-footer {
  height: 21vh;
  padding: 1.5rem 3rem;
  width: 100vw;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 1fr 1fr 4rem 1fr;
  margin-top: 3rem;
}

.footer-logo {
  height: 6rem;
  width: 7rem;
  margin-top: 1.5rem;
}

.footer-copyright {
  width: 100%;
  border-top: 0.05rem solid #FFF;
  padding-top: 1rem;
  font-size: 1.2rem;
}

@media all and (min-width: 1399px) {
  .full-width-photo {
    height: 40vw;
  }
}


@media all and (max-width: 500px) {
  .App-header {
    min-height: 0;
    grid-template-columns: 1fr 10rem 1fr;
    grid-template-rows: 14rem 3rem 4.5rem 6rem 18rem 4rem 1fr;
    grid-template-areas:
      " . . ."
      " . header-logo . "
      " . header-tagline . "
      "header-menu header-menu header-menu"
      "header-headline header-headline header-headline"
      "header-call-to-action header-call-to-action header-call-to-action"
  }

  .App-menu {
    margin: 1rem auto;
  }

  .Headline-one {
    padding: 2.5rem;
    border-width: 0.25rem;

  }

  .Headline-one span {
    text-align: center;
  }

  .Header-call-to-action button {
    margin-right: 2.5rem;
  }

  .App-end-footer {
    padding: 1.5rem 2rem;
    grid-template-rows: 1fr 1fr 3rem 1fr;
    margin-top: 2rem;
  }

  .footer-logo {
    height: 5rem;
  }

  .full-width-photo,
  .half-width-photo {
    width: auto;
    min-height: 80vw;
    margin: 0 auto;
    float: none;
  }

  .quote {
    padding: 0.5rem 0 0 5rem;
    font-size: 1.7rem;
  }

  .photo-gallery {
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }

  .photo-gallery-item {
    width: auto;
    height: 80vw;
  }

  .showcase-element-photo {
    height: 100vw;
  }

  .mailing-list-section {
    display: block;
  }

  .mailing-list-email {
    width: calc(80vw - 3rem);
  }

  .mailing-list-text {
    margin-bottom: 1rem;
  }

  .featured-project {
    display: block;
  }

  .featured-project-cover {
    width: 100%;
  }

  .featured-project-text {
    width: 100%;
    padding-left: 0;
    margin: 2rem 0;
  }

  .most-recent-project {
    display: block;
  }

  .most-recent-project-cover {
    width: 100%;
  }

  .most-recent-project-text {
    width: 100%;
    padding-left: 0;
    margin: 2rem 0;
  }

  .page-abstract {
    padding: 0 1.5rem;
  }
}

.highlight {
  font-size: 1.3rem;
  background: #636569;
  color: white;
  padding: 1rem;
  clear: both;
  border-radius: 0.5rem;
}

@media all and (min-width: 599px) {
  
}


@media all and (min-width: 799px) {
  
}

@media all and (max-width: 800px) {
  .showcase {
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .showcase-element,
  .showcase-element:first-child {
    width: 100%;
  }
}

@media all and (min-width: 1399px) {
  
}